<template>
  <div class="box">
    <div class="list-box">
      <!-- <img class="logo" src="@/assets/cherry-game-logo.png" /> -->
      <div
        v-for="(item, index) in gameList"
        :key="index"
        class="game-box"
        :title="item.name"
        @click="goGame(index)"
      >
        <div>
          <img class="game-img" :src="item.cover" />
        </div>
        <div class="content-box">
          <div class="tag-box">
            <div v-for="(item2, index2) in item.tag" :key="index2" class="tag">
              {{ item2.name }}
            </div>
          </div>
          <div class="name">{{ item.name }}</div>
          <div class="describe">{{ item.describe }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

let that;

export default {
  name: "App",
  data() {
    return {
      // 游戏列表
      gameList: [],
    };
  },
  created() {
    that = this;

    axios.get("/index.php/home/home/game").then((response) => {
      that.gameList = response.data.data;
    });
  },
  methods: {
    // 进入游戏
    goGame(index) {
      if (that.isPhone()) {
        window.location.href = that.gameList[index].app_url;
      } else {
        window.location.href = that.gameList[index].pc_url;
      }
    },

    // 判断设备类型是否为手机
    isPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
  },
};
</script>

<style>
.content-box {
  padding: 14px 20px 20px 20px;
}
.describe {
  color: #868c92;
  font-size: 14px;
}
.name {
  color: #1d2127;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 7px;
}
.tag {
  border-radius: 4px;
  border: 1px solid #2ca9fd;
  color: #2ca9fd;
  font-size: 12px;
  font-weight: 700;
  padding: 1.5px 6px;
  margin-right: 6px;
  margin-top: 6px;
}
.tag-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 7px;
}
.tag-het-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.game-img {
  width: 100%;
  height: 340px;
  max-height: 50vw;
  vertical-align: top;
  object-fit: cover;
}
.logo {
  width: 300px;
  max-width: 90vw;
  height: auto;
}
.game-box {
  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 10%);
  width: 600px;
  max-width: 90vw;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
}
.list-box {
  padding: 20px 0;
}
.box {
  display: flex;
  justify-content: center;
}
body {
  background: #f5f7f8;
}
</style>
